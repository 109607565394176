import {
  Box, Button, Container, LinearProgress, Typography,
} from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Reset } from 'styled-reset';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthenticatedApp } from './authentication/components/authenticated-app';
import { theme } from './style/style';
import logo from './assets/common/key-lsi-dark.svg';
import './style/css/satoshi.css';
import { usePageViews } from './hooks/tracking/page-view.hook';
import { useGetScreenHint, useGetTypeFormResult } from './authentication/hooks/auth0-get-params.hook';
import { useTypeformRecorder } from './authentication/hooks/typeform-recorder.hook';

function Authentication() {
  const {
    isLoading, isAuthenticated, error, loginWithRedirect, logout,
  } = useAuth0<{
    sub: any;
    name: string;
  }>();

  const storeTypeformToLocalStorage = useTypeformRecorder();
  const screen_hint = useGetScreenHint();
  const typeform_result = useGetTypeFormResult();

  if (isLoading) {
    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '90vh',
          width: '100%',
        }}
      >
        <Box
          height="170px"
          width="fit-content"
          justifyContent="flex-start"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Box component="img" src={logo} width="100%" height="40px" sx={{ objectFit: 'contain' }} marginBottom={3} />
          <Typography
            textTransform="uppercase"
            variant="h6"
            fontWeight="300"
            letterSpacing="0.1rem"
          >
            Chargement ...
          </Typography>
          {!error
            && (
              <Box width="100%">
                <LinearProgress sx={{ height: '2px' }} variant="indeterminate" color="secondary" />
              </Box>
            )}
          {error && (
            <Typography variant="body1" fontWeight="600" paddingTop={5} color="error">
              {`erreur : ${error}`}
            </Typography>
          )}
        </Box>
      </Container>
    );
  }
  if (error) {
    console.log({ error });
    // eslint-disable-next-line no-shadow
    const justifyError = (error: string) => {
      switch (error) {
        case 'Use Google Login':
          return ({
            message: (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontWeight={500}>Vous avez déjà un compte avec cet e-mail.</Typography>
                <Typography fontWeight={500}>Merci d‘utiliser la connexion e-mail/mot de passe.</Typography>
              </Box>
            ),
            textButton: 'Se connecter',
          });
        case 'Invalid state':
          return ({
            message: (<Typography fontWeight={500}>Une erreur est survenue</Typography>),
            textButton: 'Se déconnecter',
          });
        default:
          return ({
            message: (
              <Typography fontWeight={500}>{error}</Typography>),
            textButton: 'Se déconnecter',
          });
      }
    };

    return (
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '90vh',
          width: '100%',
        }}
      >
        <Box width="fit-content" display="flex" alignItems="center" flexDirection="column">
          <Box component="img" src={logo} width="100px" height="100%" sx={{ objectFit: 'contain' }} marginBottom={5} />
          <Typography
            textTransform="uppercase"
            variant="h6"
            fontWeight="700"
            letterSpacing="0.1rem"
            gutterBottom
          >
            Oops...
          </Typography>
          {justifyError(error?.message)?.message}
          <Button
            color="secondary"
            fullWidth
            variant="contained"
            onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
            sx={{
              marginTop: 3,
              letterSpacing: { xs: 'inherit', sm: '0.1rem' },
            }}
          >
            {justifyError(error?.message)?.textButton}
          </Button>
        </Box>
      </Container>
    );
  }

  if (isAuthenticated) {
    return <AuthenticatedApp logout={() => logout()} />;
  }

  storeTypeformToLocalStorage();

  return (
    <>
      {loginWithRedirect({ screen_hint, typeform_result })}
    </>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  usePageViews();
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      scope="email profile openid"
    >
      <ThemeProvider theme={theme}>
        <Reset />
        <GlobalContainer>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider variant="error" autoHideDuration={5000}>
              <Authentication />
            </SnackbarProvider>
          </QueryClientProvider>
        </GlobalContainer>
      </ThemeProvider>
    </Auth0Provider>
  );
}

const GlobalContainer = styled(Box)(() => ({
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  scrollBehavior: 'smooth',
  webkitFontSmoothing: 'antialiased',
}));

export default App;
