import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@mui/material';
import { euro } from '../../common/inputs/format/euro.format';
import { PreviewProperty } from '../components/preview-property';
import surface from '../../assets/property/surface.png';
import renta from '../../assets/property/rentabilite.png';
import price from '../../assets/property/price.png';
import budget from '../../assets/property/budget.png';
import { flatTypeOptions, offersOptions, propertyStatusOptions } from '../../data/data';
import { useGetMatchingListByProjectId } from '../../hooks/query/get-matching-list-by-project-id-query.hook';
import logo from '../../assets/common/key-lsi-dark.svg';
import { MessageContainer } from '../../common/components/message-container';

function ContainerPreviewProperty({ queryInfoById }: any) {
  const [searchParams] = useSearchParams();
  const [data, setData]: any = useState([]);
  const [props, setProps]: any = useState({});
  const queryInfo = useGetMatchingListByProjectId(searchParams.get('projectId'));

  useEffect(() => {
    setData(queryInfo?.data?.getMatchingListByProjectId?.map((e) => (
      {
        summary: {
          title: e?.project?.offer ? `Projet ${offersOptions?.find((f) => f.value === e?.project?.offer)?.label} ${e?.property?.description?.flat_type ? flatTypeOptions?.find((f) => f.value === e?.property?.description?.flat_type)?.label : ''} ${e?.property?.description?.surface ? `de ${e?.property?.description?.surface}m2` : ''} ${e?.property?.localisation?.street_address?.locality ? `à ${e?.property?.localisation?.street_address?.locality} ${e?.property?.localisation?.street_address?.zipcode}` : '' || ''}` : '',
        },
        address: e?.property?.localisation?.street_address?.address_line_1,
        status: propertyStatusOptions?.find((f) => f.value === e?.property?.status)?.label,
        // @ts-ignore
        image: e?.property?.propertyPictureDocuments?.find((f) => f?.mimeType?.includes('image'))?.url,
        dataCase: [{
          title: '💰 Budget global',
          value: e?.property?.finance?.total_budget ? euro.format(e?.property?.finance?.total_budget) : '',
          icon: budget,
        }, {
          title: '🏠 Loyer prévu',
          value: e?.property?.description?.expected_rent ? euro.format(e?.property?.description?.expected_rent) : '',
          icon: price,
        }, {
          title: '💵 Prix / m2',
          value: e?.property?.finance?.expected_price_per_m2 ? euro.format(e?.property?.finance?.expected_price_per_m2) : '',
          icon: surface,
        }, {
          title: '📈 Rentabilité',
          value: e?.property?.finance?.lsi_profitability ? `${e?.property?.finance?.lsi_profitability.toFixed(1)}%` : '',
          icon: renta,
        }],
        matchingId: e?._id,
        state: e?.state,
      }
    )));
  }, [queryInfo?.data?.getMatchingListByProjectId]);

  useEffect(() => {
    setProps({
      data,
      queryInfoById,
    });
  }, [queryInfoById, data, queryInfo.isSuccess]);

  return (
    <>
      {queryInfo && queryInfo.isSuccess
        ? <PreviewProperty {...props} />
        : (
          <MessageContainer>
            <Box width="100%" display="flex" justifyContent="center">
              <Box display="flex" alignItems="center" flexDirection="column" width="fit-content">
                <Box
                  component="img"
                  src={logo}
                  width="50px"
                  height="100%"
                  sx={{ objectFit: 'contain' }}
                  marginBottom={5}
                />
                <Typography
                  textTransform="uppercase"
                  variant="h6"
                  fontWeight="300"
                  letterSpacing="0.1rem"
                >
                  Chargement ...
                </Typography>
                <Box width="100%">
                  <LinearProgress sx={{ height: '2px' }} variant="indeterminate" color="secondary" />
                </Box>
              </Box>
            </Box>
          </MessageContainer>
        )}
    </>
  );
}

export {
  ContainerPreviewProperty,
};
